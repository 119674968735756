<template>
  <div class="dashboard-panel">
    <div class="container">
      <div class="info-section">
        <div class="_section-head">
          <h5>Hi {{ studentName }}</h5>
          <ul class="list-unstyled d-flex time-list">
            <li>{{ getDate(date) }}</li>
            <li>{{ time }}</li>
          </ul>
        </div>
        <div class="_section-body">
          <div class="content">
            <h6 class="mb-4">Happening Now</h6>
            <div class="row">
              <CircularLoader v-if="isLoadingNextSchedule" />
              <CourseScheduleCard
                v-else
                v-for="(schedule, scheduleIndex) in schedules"
                :key="scheduleIndex"
                :schedule="schedule"
              />
            </div>
            <EmptyList
              v-if="schedules.length == 0 && !isLoadingNextSchedule"
              messageText="No Live Class Now!"
            />
          </div>
          <hr />
          <div class="_inner-tabs">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  :class="{ active: activeThisTab }"
                  id="pills-exam-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-exam"
                  type="button"
                  role="tab"
                  aria-controls="pills-exam"
                  aria-selected="true"
                >
                  Exams Today
                </button>
              </li>
              <li class="nav-item ms-3" role="presentation ">
                <button
                  class="nav-link"
                  :class="{ active: !activeThisTab }"
                  id="pills-assignment-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-assignment"
                  type="button"
                  role="tab"
                  aria-controls="pills-assignment"
                  aria-selected="false"
                >
                  Active Assignments
                </button>
              </li>
              <li class="nav-item ms-3" role="presentation ">
                <button
                  class="nav-link"
                  :class="{ active: !activeThisTab }"
                  id="pills-feedback-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-feedback"
                  type="button"
                  role="tab"
                  aria-controls="pills-feedback"
                  aria-selected="false"
                >
                  Active Feedback
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade"
                :class="{ 'show active': activeThisTab }"
                id="pills-exam"
                role="tabpanel"
                aria-labelledby="pills-exam-tab"
              >
                <div v-if="exams.length > 0" class="content">
                  <div
                    class="exam-list"
                    v-for="(exam, examIndex) in exams"
                    :key="examIndex"
                  >
                    <div class="_head">
                      <p class="_title">
                        {{ exam.title }}
                        <span class="_subject">{{
                          exam.exam_subjects[0].subject.subject_title
                        }}</span>
                      </p>
                    </div>
                    <div class="_lead">
                      <p class="_program-title">
                        {{ exam.program.program_title }}
                      </p>
                      <p class="_class">
                        {{ exam.program.standard.standard_title }}
                      </p>
                      <span class="status green">{{
                        status(exam.status)
                      }}</span>
                      <router-link
                        :to="{
                          name: 'CourseExam',
                          params: {
                            program_id: exam.program_id,
                            exam_id: exam.id,
                          },
                        }"
                        v-if="
                          exam.status === 'START' &&
                          exam.exam_attended === false
                        "
                        class="btn small-cta"
                        >Attend</router-link
                      >
                      <router-link
                        :to="{
                          name: 'CourseExam',
                          params: {
                            program_id: exam.program_id,
                            exam_id: exam.id,
                          },
                        }"
                        v-else-if="
                          (exam.status === 'START' &&
                            exam.exam_attended === true) ||
                          (exam.status === 'END' && exam.exam_attended === true)
                        "
                        class="btn small-cta attended"
                        >Result</router-link
                      >
                      <a
                        href="javascript:void(0);"
                        v-else-if="exam.status === 'PUBLISHED'"
                        class="btn small-cta pending"
                        >Not Scheduled</a
                      >
                      <a
                        href="javascript:void(0);"
                        v-else-if="exam.status === 'END'"
                        class="btn small-cta ended"
                        >Ended</a
                      >
                    </div>
                  </div>
                </div>
                <EmptyList v-else messageText="No exams today!" />
              </div>
              <div
                class="tab-pane fade"
                :class="{ 'show active': !activeThisTab }"
                id="pills-assignment"
                role="tabpanel"
                aria-labelledby="pills-assignment-tab"
              >
                <div v-if="assignments.length > 0" class="content">
                  <div
                    class="assignment-list"
                    v-for="(assignment, assignmentIndex) in assignments"
                    :key="assignmentIndex"
                  >
                    <div class="_head">
                      <p class="_title">
                        {{ assignment.title }}
                        <span class="_subject">{{
                          assignment.subject.subject_title
                        }}</span>
                      </p>
                    </div>
                    <div class="_lead">
                      <p class="_program-title">
                        {{ assignment.program.program_title }}
                      </p>
                      <p>Last Date: {{ getDate(assignment.last_date) }}</p>
                      <p
                        v-if="assignment.submitted_status == 'DRAFT'"
                        class="status"
                      >
                        Draft
                      </p>
                      <p
                        class="status"
                        v-else-if="assignment.submitted_status == 'PUBLISHED'"
                      >
                        Submitted
                      </p>
                      <p class="status" v-else>Pending</p>
                      <router-link
                        :to="{
                          name: 'CourseAssignment',
                          params: {
                            program_id: assignment.program_id,
                            assignment_id: assignment.id,
                          },
                        }"
                        class="btn small-cta"
                        >Details
                      </router-link>
                    </div>
                  </div>
                </div>
                <EmptyList v-else messageText="No active assignments found!" />
              </div>
              <div
                class="tab-pane fade"
                :class="{ 'show active': !activeThisTab }"
                id="pills-feedback"
                role="tabpanel"
                aria-labelledby="pills-feedback-tab"
              >
                <div v-if="feedbacks.length > 0" class="content">
                  <div
                    class="exam-list"
                    v-for="(feedback, feedbackIndex) in feedbacks"
                    :key="feedbackIndex"
                  >
                    <div class="_head">
                      <p class="_title">
                        {{ feedback.feedback }}
                        <span class="_subject">{{
                          feedback.category_title
                        }}</span>
                      </p>
                    </div>
                    <div class="_lead">
                      <p class="_program-title">
                        Published Date: {{ getDate(feedback.published_at) }}
                      </p>
                      <p class="_class">Rating {{ feedback.rating }}</p>

                      <p class="status">{{ feedback.status }}</p>
                      <router-link
                        :to="{
                          name: 'FeedbackView',
                          params: {
                            feedback_id: feedback.id,
                          },
                        }"
                        class="btn small-cta"
                        >Details
                      </router-link>
                    </div>
                  </div>
                </div>
                <EmptyList v-else messageText="No feedback!" />
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-8">
              <h6>Attendance</h6>
              <div class="content">
                <div class="row">
                  <div class="col-md-6">
                    <v-calendar
                      class="custom-calendar max-w-full"
                      :attributes="attendances"
                    >
                    </v-calendar>
                  </div>
                  <div class="col-md-6">
                    <div class="progress-wrap">
                      <circle-progress
                        :percent="percentage"
                        :fill-color="color"
                        empty-color="#bbb2b2"
                      />
                      <p>Total Class : {{ totalClasses }}</p>
                      <p>Total Presents : {{ totalPresents }}</p>
                      <p>Percentage: {{ percentage }}%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="notification-wrap">
                <h6 class="_title-head">
                  notifications
                  <i
                    class="fas fa-check-double"
                    @click="markAllNotificationsRead"
                    v-if="notificationCount > 0"
                    title="Mark all as Read"
                    ><sup>{{ notificationCount }}</sup></i
                  >
                </h6>
                <div class="_body">
                  <CircularLoader v-if="isNotificationsLoading" />
                  <div v-else>
                    <ul
                      v-if="!isNotificationsLoading && notifications"
                      class="list-unstyled notifylist"
                    >
                      <li
                        v-for="notification in notifications"
                        :key="notification.id"
                      >
                        <a
                          href="javascript:void(0);"
                          @click="markNotificationAsRead(notification)"
                        >
                          <p
                            class="title"
                            :class="
                              notification.read_at == null
                                ? 'unread-notification'
                                : ''
                            "
                            v-html="notification.data.text"
                          ></p>
                          <p class="time">
                            {{
                              notificationDateFormatted(
                                notification.converted_created_at
                              )
                            }}
                          </p>
                        </a>
                      </li>
                    </ul>
                    <div class="paging-wrapper py-3">
                      <v-pagination
                        v-model="notificationPage"
                        :pages="totalNotificationPages"
                        :range-size="1"
                        active-color="#DCEDFF"
                        @update:modelValue="notificationPaginateUpdateHandler"
                        v-if="totalNotifications > 0"
                      />
                    </div>
                    <EmptyList
                      v-if="totalNotifications == 0"
                      messageText="All caught up!"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import errorLog from "@/errorLog";
import Timezone from "@/services/Timezone";
import AssignmentService from "@/services/AssignmentService";
import FeedbackService from "@/services/FeedbackService";
import DashboardService from "@/services/DashboardService";
import ExamService from "@/services/ExamService";
import CourseScheduleCard from "@/components/CourseScheduleCard.vue";
import CircularLoader from "@/components/loaders/CircularLoader";
import CircleProgress from "vue3-circle-progress";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import EmptyList from "@/components/EmptyList.vue";
import moment from "moment";

export default {
  name: "dashboard",
  components: {
    CourseScheduleCard,
    CircularLoader,
    CircleProgress,
    VPagination,
    EmptyList,
  },
  computed: {
    studentName() {
      let fullName = "Student";
      let user = this.$store.state.auth.user;
      // console.log(user);
      // console.log(user.length);
      if (user.length != undefined) {
        user = JSON.parse(user);
        if (user.first_name !== null) {
          if (user.last_name !== null) {
            fullName = user.first_name + " " + user.last_name;
          } else {
            fullName = user.first_name;
          }
        }
      }
      return fullName;
    },
    color() {
      if (this.percentage > 80) {
        return "#4caf50";
      } else if (this.percentage > 50) {
        return "#ff9800";
      } else {
        return " #e00a0a";
      }
    },
  },
  created() {
    setInterval(this.getTime, 1000);
    this.getNextSchedule();
    this.getTodayExams();
    this.getActiveAssignments();
    this.getActiveFeedback();
    this.getAttendance();
    this.fetchUnreadNotificationsCount();
    this.fetchAllNotifications();
  },
  data() {
    return {
      date: new Date(),
      time: "",
      activeThisTab: true,
      schedules: [],
      isLoadingNextSchedule: true,
      assignedPrograms: true,
      exams: [],
      assignments: [],
      feedbacks: [],
      attendances: [],
      percentage: 0,
      totalClasses: 0,
      totalPresents: 0,
      isNotificationsLoading: true,
      notificationCount: 0,
      // Initial page number for notification if any
      notificationPage: 1,
      currentNotificationPage: 1,
      totalNotificationPages: 0,
      totalNotifications: 0,
      notifications: [],
    };
  },
  methods: {
    getNextSchedule() {
      this.isLoadingNextSchedule = true;
      DashboardService.livePrograms()
        .then((result) => {
          this.schedules = result.data;
          this.isLoadingNextSchedule = false;
        })
        .catch((error) => {
          this.isLoadingNextSchedule = false;
          errorLog.log(error);
        });
    },
    getDate(date) {
      return Timezone.convertedTimeZone(date).format("ddd, Do MMM, YYYY");
    },
    getTime: function () {
      const today = new Date();
      let hours = today.getHours();
      if (hours < 10) {
        hours = "0" + hours;
      }
      let minutes = today.getMinutes();
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      let seconds = today.getSeconds();
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      const time = hours + ":" + minutes + ":" + seconds;
      this.time = time;
    },
    pageChangeHandler(selectedPage) {
      this.page = selectedPage;
      this.getThreads();
    },
    getTodayExams: function () {
      ExamService.getTodayExams()
        .then((result) => {
          this.exams = result.data;
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    getActiveAssignments: function () {
      AssignmentService.getActiveSubscribedProgramAssignments()
        .then((result) => {
          this.assignments = result.data.assignments.data;
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    getActiveFeedback: function () {
      FeedbackService.getActiveFeedback()
        .then((result) => {
          this.feedbacks = result.data.active_feedback;
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    status(status) {
      let returnStatus = "";
      switch (status) {
        case "DRAFT":
          returnStatus = "Draft";
          break;
        case "PUBLISHED":
          returnStatus = "Published";
          break;
        case "START":
          returnStatus = "Started";
          break;
        case "END":
          returnStatus = "Completed";
          break;
      }
      return returnStatus;
    },
    getAttendance: function () {
      DashboardService.getAttendance()
        .then((result) => {
          this.attendances = result.data.attendances;
          this.attendances.push({
            key: "today",
            highlight: true,
            dates: new Date(),
          });
          this.totalClasses = result.data.total_class;
          this.totalPresents = result.data.total_presents;
          this.percentage = result.data.percentage;
        })
        .catch((error) => {
          this.assignmentLoader = false;
          errorLog.log(error);
        });
    },
    fetchUnreadNotificationsCount() {
      DashboardService.getUnreadNotificationsCount()
        .then((result) => {
          if (result.data.status === "SUCCESS") {
            this.notificationCount = result.data.notification_count;
          }
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    fetchAllNotifications() {
      this.isNotificationsLoading = true;
      let params = {
        page: this.currentNotificationPage,
      };
      DashboardService.getAllNotifications(params)
        .then((result) => {
          if (result.data.status === "SUCCESS") {
            this.notifications = result.data.notifications.data;
            this.isNotificationsLoading = false;
            // works only when totalNotificationPages should be the last_page value
            this.totalNotificationPages = result.data.notifications.last_page;
            this.currentNotificationPage =
              result.data.notifications.current_page;
            this.totalNotifications = result.data.notifications.total;
          }
        })
        .catch((error) => {
          this.isNotificationsLoading = false;
          errorLog.log(error);
        });
    },
    notificationPaginateUpdateHandler(selectedPage) {
      if (this.currentNotificationPage !== selectedPage) {
        this.currentNotificationPage = selectedPage;
        this.fetchAllNotifications();
      }
    },
    markAllNotificationsRead() {
      DashboardService.readAllNotifications()
        .then((result) => {
          if (result.data.status === "SUCCESS") {
            this.fetchUnreadNotificationsCount();
            this.fetchAllNotifications();
          }
        })
        .catch((error) => {
          errorLog.log(error);
        });
    },
    markNotificationAsRead(notification) {
      let params = {
        notification_id: notification.id,
      };
      if (notification.read_at == null) {
        DashboardService.readNotification(params)
          .then((result) => {
            if (result.data.status === "SUCCESS") {
              this.fetchUnreadNotificationsCount();
              this.fetchAllNotifications();
            }
          })
          .catch((error) => {
            errorLog.log(error);
          });
      }
    },
    notificationDateFormatted(date) {
      let convertedDateFormatted = moment(date).format("MMM D, YYYY");
      return convertedDateFormatted;
    },
  },
};
</script>

<style lang="scss">
@import "@/style/dashboard.scss";
</style>
