import Http from "@/services/Http";

export default {
  getAllFeedback(data) {
    return Http().post("feedback/view/all", data);
  },
  saveFeedback(data) {
    return Http().post("feedback/save", data);
  },
  getFeedback(data) {
    return Http().post("feedback/edit", data);
  },
  publishFeedback(data) {
    return Http().post("feedback/publish", data);
  },
  fetchFeedbackReplies(data) {
    return Http().post("feedback/reply", data);
  },
  updateFeedbackStatus(data) {
    return Http().post("feedback/status/update", data);
  },
  saveReferences(data) {
    return Http().post("references/save", data);
  },
  getActiveFeedback() {
    return Http().post("feedback/active");
  },
};
