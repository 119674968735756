<template>
  <div class="col-md-3">
    <div class="card module-card" :class="{ live: schedule.live_status }">
      <img
        v-if="schedule.image != null"
        :src="schedule.image"
        class="card-img-top"
        alt="..."
      />
      <img
        v-else
        src="../assets/maths-illus.png"
        class="card-img-top"
        alt="..."
      />
      <div v-if="schedule && schedule.program != null" class="card-body">
        <router-link
          :to="{ name: 'MyCourseDetails', params: { id: schedule.program.id } }"
        >
          <h5 class="card-title">{{ schedule.program.program_title }}</h5>
        </router-link>
        <span class="_subject">
          {{ schedule.subject.subject_title }}
        </span>
        <p class="card-text">
          <span>
            <i class="far fa-clock"></i>
          </span>
          {{ convertedTimeForUser }}
        </p>
        <router-link
          class="cta-play"
          to="#"
          @click.prevent="startClass(schedule.schedule_id)"
        >
          <i class="fas fa-play"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Timezone from "@/services/Timezone";
import MeetingService from "@/services/MeetingService";
export default {
  name: "CourseScheduleCard",
  props: ["schedule"],
  computed: {
    convertedTimeForUser() {
      let convertedDay = Timezone.convertedTimeZone(
        this.schedule.start_time
      ).format("ddd, Do MMM");
      let convertedStartTime = Timezone.convertedTimeZone(
        this.schedule.start_time
      ).format("h:mm A");
      let convertedEndTime = Timezone.convertedTimeZone(
        this.schedule.end_time
      ).format("h:mm A");
      let convertedTime =
        convertedDay + " | " + convertedStartTime + " - " + convertedEndTime;
      return convertedTime;
    },
  },
  methods: {
    startClass(id) {
      let params = {
        schedule_id: id,
      };
      MeetingService.getMeetingDetails(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let routeData = this.$router.resolve({
              name: "CourseMeeting",
              params: { id: id },
            });
            window.open(routeData.href, "_blank");
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/module-card.scss";
</style>
