import Http from "@/services/Http";

export default {
  getAllSubscribedProgramAssignments(data) {
    return Http().post("subscribed/programs/assignments/all", data);
  },
  getActiveSubscribedProgramAssignments(data) {
    return Http().post("subscribed/programs/assignments/active", data);
  },
  getSubscribedProgramAssignment(data) {
    return Http().post("subscribed/program/assignment/view", data);
  },
  saveAssignment(data) {
    return Http().post("subscribed/program/assignment/save", data);
  },
  uploadAssignmentFiles(file, uploadId, assignmentId, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("assignment_upload_id", uploadId);
    formData.append("assignment_id", assignmentId);

    return Http().post("subscribed/program/assignment/file-upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  },
  submitAssignment(data) {
    return Http().post("subscribed/program/assignment/submit", data);
  },
  downloadFile(data) {
    return Http().get(
      "subscribed/program/assignment/download-file?file=" + data,
      {
        responseType: "arraybuffer",
      }
    );
  },
  deleteFile(data) {
    return Http().post("subscribed/program/assignment/delete-uploads", data);
  },
  getSubscribedPrograms() {
    return Http().post("load/subscribed/programs");
  },
};
